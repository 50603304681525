import { createI18n } from 'vue-i18n'

function customRule(choice, choicesLength, orgRule) {
    if (choice === 0) {
        return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1
    if (!teen && endsWithOne) {
        return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
    }

    return choicesLength < 4 ? 2 : 3
}

const messages = {
    ru: {
        slide: '0 слайдов | {n} слайд | {n} слайда | {n} слайдов',
    }
}

const i18n = createI18n({
    locale: 'ru', // set locale
    pluralizationRules: {
        ru: customRule
    },
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

export default i18n;
